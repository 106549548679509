import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="論理回路について" title="論理回路" keywords={['logic circuit']} mdxType="SEO" />
    <h1 {...{
      "id": "cpu"
    }}>{`CPU`}</h1>
    <p>{`CPUとはメモリからロードしたプログラムを実行する装置を指します。`}</p>
    <p>{`CPUは複数の構成要素から成り立っています。`}</p>
    <p><img alt="cpu" src={require("./cpu.png")} /></p>
    <p>{`上記の図はCPUの構成要素を図示したものです。`}</p>
    <p>{`汎用レジスタ: CPUでの計算結果を一時的に保存出来る記憶装置。システムメモリより高速なに動作する。`}</p>
    <p>{`ALU: オペコードとオペランドを受け取り、その命令を実行する装置。オペランドが含まれない命令も存在する。`}</p>
    <p>{`プログラムカウンター: 次に読み込むプログラムのアドレスを記憶する装置。`}</p>
    <p>{`スタックポイント: LIFOと呼ばれるデータ構造(スタック)が格納されているメモリのアドレスを保持する。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      