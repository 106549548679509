// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-computer-system-logic-ciercuit-mdx": () => import("./../../src/pages/computer-system/logic-ciercuit.mdx" /* webpackChunkName: "component---src-pages-computer-system-logic-ciercuit-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-scala-unfamiliar-syntax-mdx": () => import("./../../src/pages/scala/unfamiliar-syntax.mdx" /* webpackChunkName: "component---src-pages-scala-unfamiliar-syntax-mdx" */)
}

