import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
import SyntaxHighlighter from 'react-syntax-highlighter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="論理回路について" title="論理回路" keywords={['scala']} mdxType="SEO" />
    <h1 {...{
      "id": "scalaでの見慣れない文法一覧"
    }}>{`Scalaでの見慣れない文法一覧`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{``}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      