import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../components/Seo';
<SEO title="ymizushiの技術徒然" />;
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "これはなに？"
    }}>{`これはなに？`}</h1>
    <p>{`ymizushiのストック型技術ドキュメントです。`}</p>
    <p>{`日々学習したことや理解したことを体系的にまとめていく場として使用しています。`}</p>
    <p>{`間違いなどありましたら、 `}<a parentName="p" {...{
        "href": "https://github.com/ymizushi/docs.ymizushi.work/issues"
      }}>{`Issue`}</a>{` まで報告いただけると幸いです。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      